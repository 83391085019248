import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_APP_VERSION } from "../../redux/commonSlice";
import getConfigJSON from "../../helperFunctions/getConfigJSON";
import "./AppVersionBadge.css";

const AppVersionBadge = () => {
  const { appVersion } = useSelector((state) => state.commonReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    setAppVersion();
  }, []);

  const setAppVersion = async () => {
    const { APP_VERSION } = await getConfigJSON();
    dispatch(SET_APP_VERSION(APP_VERSION));
  };
  return <span className="app_version">{appVersion}</span>;
};
export default AppVersionBadge;
