const getCSUserMeta = () => {
  let CSToken = localStorage.getItem(`token`);

  let brandMeta = localStorage.getItem(`brandMeta`)
    ? JSON.parse(localStorage.getItem(`brandMeta`))
    : {};

  return { CSToken, brandMeta };
};
export default getCSUserMeta;
