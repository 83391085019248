const getBrandName = () => {
  let LocalStotageBrandName = localStorage.getItem("brandName");
  if (!!LocalStotageBrandName) {
    return LocalStotageBrandName;
  }
  const BRAND_DOMAIN = document.location.origin;

  let vodafoneDomains = process.env.REACT_APP_VODAFONE_DOMAINS.split(",");
  let cocacolaDomains = process.env.REACT_APP_COCACOLA_DOMAINS.split(",");
  let mercedesDomains = process.env.REACT_APP_MERCEDES_DOMAINS.split(",");
  let mastercardDomains = process.env.REACT_APP_MASTERCARD_DOMAINS.split(",");
  let zurichDomains = process.env.REACT_APP_ZURICH_DOMAINS.split(",");
  let swedbankDomains = process.env.REACT_APP_SWEDBANK_DOMAINS.split(",");
  let shellDomains = process.env.REACT_APP_SHELL_DOMAINS.split(",");
  let adacDomains = process.env.REACT_APP_ADAC_DOMAINS.split(",");
  let wppDomains = process.env.REACT_APP_WPP_DOMAINS.split(",");
  let bcgDomains = process.env.REACT_APP_BCG_DOMAINS.split(",");
  let intelDomains = process.env.REACT_APP_INTEL_DOMAINS.split(",");
  let telenorDomains = process.env.REACT_APP_TELENOR_DOMAINS.split(",");
  let ampDomains = process.env.REACT_APP_AMP_DOMAINS.split(",");
  let sonicSpaceDomains = process.env.REACT_APP_SONICSPACE_DOMAINS.split(",");
  let devDomains = process.env.REACT_APP_DEVELOPMENT_DOMAINS.split(",");

  function isDomainPresent(domainArray) {
    return domainArray.some(
      (domain) => !!domain && BRAND_DOMAIN.endsWith(domain)
    );
  }
  let brandName;
  // always add new brand's if statement at the top of if else block
  // as sonicspace/amp is the default brand
  if (isDomainPresent(vodafoneDomains)) {
    brandName = "vodafone";
  } else if (isDomainPresent(cocacolaDomains)) {
    brandName = "cocacola";
  } else if (isDomainPresent(mercedesDomains)) {
    brandName = "mercedes";
  } else if (isDomainPresent(mastercardDomains)) {
    brandName = "mastercard";
  } else if (isDomainPresent(zurichDomains)) {
    brandName = "zurich";
  } else if (isDomainPresent(swedbankDomains)) {
    brandName = "swedbank";
  } else if (isDomainPresent(shellDomains)) {
    brandName = "shell";
  } else if (isDomainPresent(adacDomains)) {
    brandName = "adac";
  } else if (isDomainPresent(wppDomains)) {
    brandName = "wpp";
  } else if (isDomainPresent(bcgDomains)) {
    brandName = "bcg";
  } else if (isDomainPresent(intelDomains)) {
    brandName = "intel";
  } else if (isDomainPresent(telenorDomains)) {
    brandName = "telenor";
  } else if (isDomainPresent(sonicSpaceDomains)) {
    brandName = "sonicspace";
  } else if (isDomainPresent(ampDomains)) {
    brandName = "amp";
  } else if (isDomainPresent(devDomains)) {
    brandName = "sonicspace";
  } else {
    brandName = "sonicspace";
  }
  if (process.env.NODE_ENV === "development") {
    brandName = "sonicspace";
  }
  localStorage.setItem("brandName", brandName);

  return brandName;
};

export default getBrandName;
