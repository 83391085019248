import { Navigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import NavStrings from "../constants/NavStrings";
import { useDispatch, useSelector } from "react-redux";
import {
  REMOVE_AUTH_STATE,
  SET_AUTH_META,
} from "../../modules/auth/redux/authSlice";
import FallBackPage from "../../common/components/FallBackPage/FallBackPage";
import getCurrentUser from "../../modules/auth/services/getCurrentUser";
import getCSUserMeta from "../../utils/getCSUserMeta";

const ProtectedRoute = ({ children }) => {
  const dispatch = useDispatch();
  const { authMeta, authAction } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = () => {
    let { CSToken } = getCSUserMeta();

    if (CSToken) {
      getCurrentUser({
        onSuccess: (response) => {
          onGetCurrentUserSuccess(response);
        },
        onError: () => {
          dispatch(REMOVE_AUTH_STATE());
          setLoading(false);
        },
      });
    } else {
      dispatch(REMOVE_AUTH_STATE());
      setLoading(false);
    }
  };

  const onGetCurrentUserSuccess = async (response) => {
    if (response?.data?.enabled && !response?.data?.blocked) {
      dispatch(
        SET_AUTH_META({
          authMeta: {
            username: response.data.email,
            fullName: response.data.fullName,
            id: response.data.id,
            status: true,
          },
          authAction: "LOGIN",
        })
      );
    } else {
      dispatch(REMOVE_AUTH_STATE());
    }
    setLoading(false);
  };

  if (loading || authAction === "LOGOUT") {
    return <FallBackPage />;
  }

  return authMeta?.status ? (
    children
  ) : (
    <Navigate to={NavStrings.UNAUTHORIZED} replace={true} />
  );
};

export default ProtectedRoute;
