import axios from "axios";
import getCSUserMeta from "../utils/getCSUserMeta";
const { CSToken } = getCSUserMeta();

let axiosCSPrivateInstance = axios.create({
  baseURL: "/api",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${CSToken}`,
  },
});

axiosCSPrivateInstance.interceptors.request.use(
  (config) => {
    const { CSToken } = getCSUserMeta();
    config.headers["Authorization"] = `Bearer ${CSToken}`;
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosCSPrivateInstance;
