import React, { useState, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { flattenMessages } from "../../utils/messagesUtils";
import BrandingService from "../BrandingService";
import { BrandingContext } from "./BrandingContext";
import StylingProvider from "./StylingProvider";
import getBrandName from "../../utils/getBrandName";
import getWPPThemeJson from "../wpp/theme/getWPPThemeJson";
import FallBackPage from "../../common/components/FallBackPage/FallBackPage";
const BrandingProviderWPP = ({ children }) => {
  const [config, setConfig] = useState({ isDefault: true });
  const [messages, setMessages] = useState({ isDefault: true });
  const [isLoading, setIsLoading] = useState(true);
  const [theme, setTheme] = useState({});

  let brandname = getBrandName();

  let shouldUseOsContext =
    brandname === "wpp" && process.env.NODE_ENV === "production";

  useEffect(() => {
    setIsLoading(true);
    BrandingService.get().then((result) => {
      let configResult = result[0];
      let messagesResult = result[1];
      let themeResult;
      const csStringThemeData = localStorage.getItem(`csThemeData`);
      if (shouldUseOsContext && !!csStringThemeData) {
        try {
          const csJSONThemeData = JSON.parse(csStringThemeData);
          // console.log("csJSONThemeData", csJSONThemeData);
          let themeObj = getWPPThemeJson(csJSONThemeData);
          // console.log("if cs provider BrandingProviderWPP::themeObj", themeObj);
          themeResult = themeObj;
        } catch (error) {
          console.log("error", error);
        }
      } else {
        // console.log("else getting in branding component");
        themeResult = result[0]?.theme;
      }

      updateStatesAndCallSetBodyFonts1(
        configResult,
        themeResult,
        messagesResult
      );
    });
  }, []);

  const setBodyFonts = () => {
    // console.log("BrandingProviderWPP::setBodyFonts:::config CS", config.fonts);
    var fonts = document.createElement("style");
    fonts.appendChild(document.createTextNode(config.fonts));
    document.head.appendChild(fonts);
  };

  async function updateStatesAndCallSetBodyFonts1(
    configResult,
    themeResult,
    messagesResult
  ) {
    // console.log(
    //   "BrandingProviderWPP CS::updateStatesAndCallSetBodyFonts1:::configResult",
    //   configResult
    // );
    await Promise.all([
      setConfig(configResult),
      setTheme(themeResult),
      setMessages(messagesResult),
      setIsLoading(false),
    ]);

    setBodyFonts();
    // console.log(
    //   "BrandingProviderWPP::setBodyFonts:::config CS after",
    //   config.fonts
    // );
  }

  const renderLoading = () => <FallBackPage />;

  if (isLoading) {
    return renderLoading();
  }

  const state = {
    config,
    messages,
    theme,
  };

  return (
    <BrandingContext.Provider value={state}>
      <IntlProvider locale="en" messages={flattenMessages(messages)}>
        <StylingProvider stylingVariables={theme}>{children}</StylingProvider>
      </IntlProvider>
    </BrandingContext.Provider>
  );
};

export default BrandingProviderWPP;
