import { createSlice } from "@reduxjs/toolkit";
import { TTS_STATUS } from "../constants/TTSStatus";
import { v4 as uuidv4 } from "uuid";
import roundUpToDecimal from "../../../utils/roundUpToDecimal";
import { ElevenLabsVoiceProvider } from "../constants/VoiceProviders";

const initialState = {
  selectedVoices: [],
  TTSTimelineVoicesMP3: [],
  recentlyAddedVoice: [],
  isVoiceListModalOpen: false,
  voiceListModalAction: "ADD_VOICE",
  isDictModalOpen: false,
  getUpdatedData: false,
  replaceVoiceMeta: null,
  isTTSProcessing: false,
  isTTSVoicePlaying: false,
  pendingTTSScriptIds: [],
};

function getUniqueListBy(arr, key) {
  return [...new Map(arr?.map((item) => [item[key], item]))?.values()];
}

const updateTTSTimelineMeta = (selectedVoices, TTSTimelineVoicesMP3) => {
  // console.log("updateTTSTimelineMeta callled");
  let proccesedTTS = selectedVoices
    ?.flatMap((data) => data?.content)
    ?.filter((data) => Boolean(data?.mp3));

  if (proccesedTTS?.length == 0) {
    return [];
  }
  const GAP = 0.1;
  let combinedObj = proccesedTTS?.map((x) => {
    let a = TTSTimelineVoicesMP3?.find((y) => y.voiceUUID == x.voiceUUID);
    return { ...(a || {}), ...(x || {}) };
  });

  // console.log("proccesedTTS", JSON.stringify(proccesedTTS, null, 2));
  // console.log(
  //   "TTSTimelineVoicesMP3",
  //   JSON.stringify(TTSTimelineVoicesMP3, null, 2)
  // );
  // console.log("combinedObj", JSON.stringify(combinedObj, null, 2));
  let gapIncrement = 0;
  let endPointInLoop = 0;
  let ttsObj = combinedObj.map((data, index) => {
    const prevItem = combinedObj?.[index - 1];

    const prevItemEndPoint = !prevItem
      ? 0
      : !prevItem?.startPoint
      ? endPointInLoop
      : roundUpToDecimal(+prevItem?.startPoint + +prevItem?.duration);

    // console.log("prevItem***", JSON.stringify(prevItem, null, 2));
    // console.log("prevItemEndPoint*************", prevItemEndPoint);

    let gapValue = index === 0 ? 0 : GAP;
    const isNewAdded = isNaN(parseFloat(data?.startPoint));
    let currentStartPoint = isNewAdded
      ? roundUpToDecimal(+prevItemEndPoint + +gapValue + +gapIncrement)
      : roundUpToDecimal(+data?.startPoint + +gapIncrement);
    if (prevItemEndPoint > currentStartPoint) {
      currentStartPoint = roundUpToDecimal(+prevItemEndPoint + +GAP);
    }

    gapIncrement = 0;

    let currentEndPoint = roundUpToDecimal(
      +currentStartPoint + +data?.duration
    );

    endPointInLoop = currentEndPoint;

    // console.log("currentStartPoint***", currentStartPoint);
    // console.log("currentEndPoint***", currentEndPoint);
    // console.log("endPointInLoop***", endPointInLoop);

    const nextItem = combinedObj?.[index + 1];

    const nextItemStartPoint =
      !nextItem || !nextItem?.startPoint
        ? roundUpToDecimal(+currentEndPoint + +GAP)
        : roundUpToDecimal(nextItem?.startPoint);

    // // console.log("nextItem**", JSON.stringify(nextItem, null, 2));
    // console.log("nextItemStartPoint*************", nextItemStartPoint);
    if (currentEndPoint > nextItemStartPoint) {
      // console.log("Overlap============================");
      // console.log(
      //   "Overlapped sec",
      //   currentEndPoint - nextItemStartPoint + gapValue
      // );
      gapIncrement = currentEndPoint - nextItemStartPoint + gapValue;
    } else {
      // console.log("No Overlap============================");
    }
    // console.log("gapIncrement***", gapIncrement);
    // console.log("currentStartPoint T***", currentStartPoint);
    // console.log("+currentStartPoint T***", +currentStartPoint);
    // console.log("TTS***", {
    //   mp3: data?.mp3,
    //   duration: data?.duration,
    //   gender: data?.gender,
    //   // voiceIndex: data?.voiceIndex,
    //   // index: data?.index,
    //   voiceUUID: data?.voiceUUID,
    //   startPoint: +currentStartPoint ?? 0,
    // });
    // console.log(
    //   "================================" +
    //     data?.voiceUUID +
    //     "================================="
    // );
    return {
      mp3: data?.mp3,
      duration: data?.duration,
      gender: data?.gender,
      // voiceIndex: data?.voiceIndex,
      // index: data?.index,
      voiceUUID: data?.voiceUUID,
      startPoint: +currentStartPoint ?? 0,
    };
  });
  return ttsObj;
};

const voicesSlice = createSlice({
  name: "VOICES",
  initialState,
  reducers: {
    ADD_VOICE: (state, action) => {
      state.selectedVoices?.splice(
        state.selectedVoices?.length,
        0,
        action.payload
      );
    },
    UPDATE_TTS_STATUS: (state, action) => {
      action.payload.forEach((data) => {
        state.selectedVoices[+data.voiceIndex].content[+data?.index].status =
          data?.ttsStatus;
        if ([TTS_STATUS.STARTED, TTS_STATUS.FAILED].includes(data?.ttsStatus)) {
          state.selectedVoices[+data.voiceIndex].content[+data?.index].mp3 = "";
          state.selectedVoices[+data.voiceIndex].content[
            +data?.index
          ].duration = 0;
        }
      });

      state.getUpdatedData = !state.getUpdatedData;
    },
    UPDATE_COMPLETED_TTS_META: (state, action) => {
      let completedTTS = action.payload;
      if (!Array.isArray(action.payload) || action.payload?.length === 0)
        return;

      completedTTS.forEach((tts) => {
        let obj = state.selectedVoices
          ?.flatMap((data) => data?.content)
          ?.find((data) => data?.voiceUUID === tts.scriptId);
        if (obj) {
          state.selectedVoices[+obj.voiceIndex].content[+obj?.index].status =
            TTS_STATUS.COMPLETED;
          state.selectedVoices[+obj.voiceIndex].content[+obj?.index].mp3 =
            tts.mp3Path;
          state.selectedVoices[+obj.voiceIndex].content[+obj?.index].duration =
            roundUpToDecimal(tts.duration) || 0;
        }
      });

      // console.log(
      //   "state.selectedVoices UPDATE_COMPLETED_TTS_META",
      //   JSON.stringify(state.selectedVoices, null, 2)
      // );

      let completedTTSScriptIds =
        completedTTS?.map((completedTTS) => completedTTS.scriptId) || [];

      state.pendingTTSScriptIds = state.pendingTTSScriptIds.filter(
        (item) => !completedTTSScriptIds.includes(item)
      );

      state.TTSTimelineVoicesMP3 = updateTTSTimelineMeta(
        state.selectedVoices,
        state.TTSTimelineVoicesMP3
      );
      state.getUpdatedData = !state.getUpdatedData;
    },
    ADD_NEW_TTS_SCRIPT_IDS: (state, action) => {
      state.pendingTTSScriptIds = [
        ...new Set([...state.pendingTTSScriptIds, ...(action.payload || [])]),
      ];
    },
    REMOVE_VOICE: (state, action) => {
      state.selectedVoices = state.selectedVoices
        ?.filter((voice, i) => i !== action.payload?.voiceIndex)
        ?.map((item, itemIndex) => {
          if (item) {
            return {
              ...item,
              content:
                item?.content?.map((contentItem) => ({
                  ...contentItem,
                  voiceIndex: itemIndex,
                })) || [],
            };
          } else {
            return;
          }
        });

      state.TTSTimelineVoicesMP3 = state.TTSTimelineVoicesMP3?.filter(
        (data) => data.mp3 !== action.payload?.mp3
      );
      state.pendingTTSScriptIds = state.pendingTTSScriptIds?.filter(
        (data) => data !== action.payload?.content?.[0]?.voiceUUID
      );
    },
    ADD_RECENT_VOICE: (state, action) => {
      let newRecentlyAddedVoiceList = getUniqueListBy(
        [action.payload, ...state.recentlyAddedVoice],
        "name"
      );
      if (
        newRecentlyAddedVoiceList?.length >
        window?.globalConfig?.TOP_RECENTLY_ADDED_VOICE_RESULTS
      ) {
        newRecentlyAddedVoiceList = newRecentlyAddedVoiceList?.slice(
          0,
          window?.globalConfig?.TOP_RECENTLY_ADDED_VOICE_RESULTS
        );
      }
      state.recentlyAddedVoice = newRecentlyAddedVoiceList;
    },
    ADD_CONTENT: (state, action) => {
      state.selectedVoices[action.payload.voiceIndex].content =
        action.payload?.inputList;

      const removedTextBoxMp3 = action.payload?.removedTextBoxMp3;
      if (!!removedTextBoxMp3) {
        state.TTSTimelineVoicesMP3 = state.TTSTimelineVoicesMP3?.filter(
          (data) => data?.mp3 !== removedTextBoxMp3
        );
      }
      const removedTextBoxUUID = action.payload?.removedTextBoxUUID;
      if (!!removedTextBoxUUID) {
        state.pendingTTSScriptIds = state.pendingTTSScriptIds?.filter(
          (data) => data !== removedTextBoxUUID
        );
      }
    },
    ADD_SUBTEXT: (state) => {
      const lastContent =
        state.selectedVoices[state.selectedVoices?.length - 1]?.content;

      state.selectedVoices[state.selectedVoices?.length - 1].content =
        lastContent?.concat({
          content: "",
          voice: lastContent?.[0]?.voice,
          gender: lastContent?.[0]?.gender,
          voiceProvider: lastContent?.[0]?.voiceProvider,
          mp3: "",
          status: TTS_STATUS.NOT_STARTED,
          voiceUUID: uuidv4(),
          duration: 0,
          ...(lastContent?.[0]?.voiceProvider !== ElevenLabsVoiceProvider
            ? {
                speed: lastContent?.[0]?.speed || "1",
                pitch: lastContent?.[0]?.pitch || "1",
              }
            : {
                stability: lastContent?.[0]?.stability || "0.4",
                similarityBoost: lastContent?.[0]?.similarityBoost || "0.5",
                style: lastContent?.[0]?.style || "0.6",
                useSpeakerBoost: lastContent?.[0]?.useSpeakerBoost || true,
              }),
          speakingStyle: "general",
          index: lastContent?.length,
          voiceIndex: lastContent?.[0]?.voiceIndex,
        });
      state.getUpdatedData = !state.getUpdatedData;
    },
    UPDATE_TTS_TIMELINE_SLIDER_VALUES: (state, action) => {
      state.TTSTimelineVoicesMP3.forEach((element, index) => {
        if (action.payload?.[index] >= 0) {
          element.startPoint = roundUpToDecimal(action.payload?.[index]);
        }
      });
    },
    SET_VOICE_META: (state, action) => {
      let voiceObj = action.payload;
      for (const key in voiceObj) {
        if (Object.hasOwnProperty.call(voiceObj, key)) {
          const element = voiceObj[key];
          state[key] = element;
        }
      }
    },
    RESET_VOICE_META: () => initialState,
  },
});

export default voicesSlice.reducer;
export const {
  ADD_VOICE,
  ADD_SUBTEXT,
  REMOVE_VOICE,
  UPDATE_TTS_STATUS,
  UPDATE_COMPLETED_TTS_META,
  ADD_NEW_TTS_SCRIPT_IDS,
  GET_PENDING_TTS,
  ADD_RECENT_VOICE,
  ADD_CONTENT,
  SET_SELECTED_VOICES,
  UPDATE_TTS_TIMELINE_SLIDER_VALUES,
  SET_VOICE_META,
  RESET_VOICE_META,
} = voicesSlice.actions;
